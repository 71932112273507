import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { endOfDay, startOfDay, startOfMonth } from "date-fns";
import { isEqual } from "lodash";

import { IPaginationState } from "../../../modules/filter/shared/interfaces/pagination-types";
import { AppThunk } from "../../../store";
import { RootState } from "../../../store-types";
import { ICropReportFilter } from "../shared/interfaces/crop-report-filter";
import {
  ITechOpListFilter,
  ITechOperationsState,
} from "../shared/interfaces/tech-operations-state";
import { TechOperation } from "../shared/models/tech-operation";
import { techOperationsService } from "../shared/services/tech-operations.service";
import { getTechOpsFilter, getPagination } from "./tech-operations.selector";
import { TECHOPERATIONS_MODULE_NAME } from "./constants";

const initialPagination: IPaginationState = {
  limit: 10,
  page: 0,
  order: [],
  offset: 0,
};

const initialState: ITechOperationsState = {
  noPredecessorsList: [],
  removeSplittedFields: false,
  filter: {
    from: startOfDay(startOfMonth(new Date())),
    to: endOfDay(new Date()),
    farmLandId: undefined, // undefined => NOTHING_SELECTED_IN_FILTER_DROPDOWN__SELECT_ALL
    cropTypeId: undefined, // undefined => NOTHING_SELECTED_IN_FILTER_DROPDOWN__SELECT_ALL
    groupId: undefined, // undefined => NOTHING_SELECTED_IN_FILTER_DROPDOWN__SELECT_ALL
    subGroupId: undefined, // undefined => NOTHING_SELECTED_IN_FILTER_DROPDOWN__SELECT_ALL
  },
  pagination: initialPagination,
};

export const techOperationsSlice = createSlice({
  name: TECHOPERATIONS_MODULE_NAME,
  initialState: initialState,
  reducers: {
    setFilterAction(state, action: PayloadAction<ITechOpListFilter>): void {
      state.filter = action.payload;
    },
    setPredecessorsList(state, action: PayloadAction<TechOperation[]>): void {
      state.noPredecessorsList = action.payload;
    },
    setRemoveSplittedFields(state, action: PayloadAction<boolean>): void {
      state.removeSplittedFields = action.payload;
    },
    setPaginationAction(state, action: PayloadAction<IPaginationState>): void {
      state.pagination = action.payload;
    },
  },
});

export const techOperationsReducer = techOperationsSlice.reducer;
export const {
  setFilterAction,
  setPredecessorsList,
  setRemoveSplittedFields,
  setPaginationAction,
} = techOperationsSlice.actions;

// eslint-disable-next-line
export const getWeeklyFileAction = createAsyncThunk<any, ICropReportFilter>(
  `${TECHOPERATIONS_MODULE_NAME}/getWeeklyFile`,
  async (data) => {
    return techOperationsService.getWeeklyFile(data);
  }
);

export function setFilterProperty(
  filterProperty: Partial<ITechOpListFilter>
): AppThunk<ITechOpListFilter> {
  return (dispatch, getState) => {
    const rootState = getState() as RootState;
    const filter: ITechOpListFilter = getTechOpsFilter(rootState);
    const filterMerged: ITechOpListFilter = { ...filter, ...filterProperty };
    if (isEqual(filterMerged, filter)) {
      return filter;
    }
    dispatch(setFilterAction(filterMerged));
    return filterMerged;
  };
}

export function setPaginationProperty(
  paginationProperty: Partial<IPaginationState>
): AppThunk<IPaginationState> {
  return (dispatch, getState) => {
    const rootState = getState() as RootState;
    const pagination: IPaginationState = getPagination(rootState);
    const paginationMerged: IPaginationState = { ...pagination, ...paginationProperty };
    paginationMerged.offset = paginationMerged.limit * paginationMerged.page;
    if (isEqual(paginationMerged, pagination)) {
      return pagination;
    }
    dispatch(setPaginationAction(paginationMerged));
    return paginationMerged;
  };
}
