import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import {
  TechOpsRequest,
  getCurrentFilter,
} from "../containers/tech-operations-list/techop-list-utils";
import { TechOperation } from "../shared/models/tech-operation";
import { ILoopbackFilter } from "../../../modules/filter/shared/interfaces/loopback";
import { LoopbackTechOpFilter } from "../containers/tech-operations-list/techop-list-utils";
import { ITechOperationDto } from "../shared/dtos/tech-operation.dto";
import { deverror, devlog } from "../../../shared/utils/log";
import { toQueryString } from "../../../shared/utils/auth-token";
import { transformAgregatedsV2ErrorResponse } from "./techop.rtkq.types";

export const techOpsApiSlice = createApi({
  reducerPath: "api-techop",
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl:
        process.env.REACT_APP_API_ENDPOINT || ".env: define REACT_APP_API_ENDPOINT",
    }),
    { maxRetries: 1 }
  ),
  tagTypes: ["TechOpsList", "TechOpsListCount"],
  endpoints: (builder) => ({
    techOpsList: builder.query<TechOperation[], TechOpsRequest>({
      query: (searchParams: TechOpsRequest) => {
        devlog(`techOpsList():`, searchParams);
        const qs = listQueryString(searchParams);
        const relPath = `TechOperationAgregatedsV2?${qs}`;
        return { url: relPath, method: "GET" };
      },
      providesTags: (result, error, arg) => {
        const ret = result
          ? [
              ...result.map(({ id }) => ({ type: "TechOpsList" as const, id })),
              { type: "TechOpsList" as const, id: "LIST" },
            ]
          : [];
        devlog(`techOpsList providesTags`, [arg, ret]);
        return ret;
      },
      transformResponse: (dtos: ITechOperationDto[]) => {
        const models = dtos.map((dto) => {
          const model = TechOperation.fromDto(dto);
          return model;
        });
        return models;
      },
      // extraOptions: { maxRetries: 3 },
    }),

    techOpsListCount: builder.query<number, TechOpsRequest>({
      query: (searchParams) => {
        devlog(`techOpsListCount():`, searchParams);
        const qs = listCountQueryString(searchParams);
        const relPath = `TechOperationAgregatedsV2/count?${qs}`;
        return { url: relPath, method: "GET" };
      },
      providesTags: (result, error, arg) => {
        const ret = result
          ? [
              { type: "TechOpsListCount" as const, id: result },
              { type: "TechOpsListCount" as const, id: "COUNT" },
            ]
          : [];
        devlog(`techOpsListCount providesTags`, [arg, ret]);
        return ret;
      },
      transformResponse: (dto: { count: number }) => {
        return dto.count;
      },
      // extraOptions: { maxRetries: 3 },
    }),

    fetchTechOp: builder.query<TechOperation, string | undefined>({
      query: (techOpId: string | undefined) => {
        // devlog(`fetchTechOperation_forId(${techOpId})`);
        const authToken = toQueryString();
        const relPath = `TechOperationAgregatedsV2/${techOpId}?${authToken}`;
        return { url: relPath, method: "GET" };
      },
      transformResponse: (dto: ITechOperationDto) => {
        const model = new TechOperation(dto.id);
        model.updateFromDto(dto);
        devlog(`fetchTechOperation_forId(${dto.id}) dto,model:`, dto, model);
        return model;
      },
      transformErrorResponse: transformAgregatedsV2ErrorResponse,
    }),

    addTechOp: builder.mutation<
      TechOperation,
      // Partial<TechOperation> & Omit<TechOperation, "id">
      TechOperation
    >({
      query: (techOpModel) => {
        devlog(`addTechOp():`, techOpModel);
        const authToken = toQueryString();
        // replacing .startDate .finishDate Date type to pre-formatted strings
        // backend doesn't like .startDate in JSON form of "2023-12-12T00:00:00.000Z"
        // const datesReplaced = {
        //   ...techOpModel,
        //   startDate: formatDate(techOpModel.startDate),
        //   finishDate: formatDate(techOpModel.finishDate),
        //   Asset: techOpModel.Asset.map((x) => x.asDto),
        // };
        const datesReplaced = techOpModel.asDto;
        return {
          url: `TechOperationAgregatedsV2?${authToken}`,
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
          body: JSON.stringify(datesReplaced),
        };
      },
      transformResponse: (response: ITechOperationDto) => {
        try {
          const ret = TechOperation.fromDto(response);
          return ret;
        } catch (ex) {
          deverror(
            `addTechOp():transformResponse(): BACKEND_RETURNED_INVALID_DTO`,
            response
          );
          throw ex;
        }
      },
      transformErrorResponse: transformAgregatedsV2ErrorResponse,
      invalidatesTags: (result, error, arg) => {
        const ret = [{ type: "TechOpsList" as const, id: "LIST" }];
        devlog(`addTechOp invalidatesTags`, [arg, ret]);
        return ret;
      },
    }),

    deleteTechOp: builder.mutation<number, string>({
      query: (techOpId) => {
        devlog(`deleteTechOp(${techOpId}):`);
        const authToken = toQueryString();
        return {
          url: `TechOperationAgregatedsV2/${techOpId}?${authToken}`,
          method: "DELETE",
        };
      },
      transformResponse: (response: { count: number }) => {
        try {
          devlog(`deleteTechOp():`, response);
          return response.count;
        } catch (ex) {
          deverror(
            `deleteTechOp():transformResponse(): BACKEND_RETURNED_INVALID_DTO`,
            response
          );
          throw ex;
        }
      },
      transformErrorResponse: transformAgregatedsV2ErrorResponse,
      invalidatesTags: (result, error, arg) => {
        const ret = [{ type: "TechOpsList" as const, id: "LIST" }];
        devlog(`deleteTechOp invalidatesTags`, [arg, ret]);
        return ret;
      },
    }),

    updateTechOp: builder.mutation<
      TechOperation,
      // Partial<TechOperation> & Pick<TechOperation, "id">
      TechOperation
    >({
      query: (techOpModel) => {
        devlog(`updateTechOp():`, techOpModel);
        const authToken = toQueryString();
        // replacing .startDate .finishDate Date type to pre-formatted strings
        // backend doesn't like .startDate in JSON form of "2023-12-12T00:00:00.000Z"
        // const datesReplaced = {
        //   ...techOpModel,
        //   startDate: formatDate(techOpModel.startDate),
        //   finishDate: formatDate(techOpModel.finishDate),
        //   Asset: techOpModel.Asset.map((x) => x.asDto),
        // };
        const datesReplaced = techOpModel.asDto;
        return {
          url: `TechOperationAgregatedsV2?${authToken}`,
          method: "PUT",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
          body: JSON.stringify(datesReplaced),
        };
      },
      transformResponse: (response: ITechOperationDto) => {
        try {
          const ret = TechOperation.fromDto(response);
          return ret;
        } catch (ex) {
          deverror(
            `updateTechOp():transformResponse(): BACKEND_RETURNED_INVALID_DTO`,
            response
          );
          throw ex;
        }
      },
      transformErrorResponse: transformAgregatedsV2ErrorResponse,
      invalidatesTags: (result, error, arg) => {
        const ret = [{ type: "TechOpsList" as const, id: "LIST" }];
        devlog(`updateTechOp invalidatesTags`, [arg, ret]);
        return ret;
      },
    }),
  }),
});

function listQueryString(searchParams: TechOpsRequest): string {
  const { paginationState, filtersState, seasonId, farmId, onlyOriginal } = searchParams;
  const filter: ILoopbackFilter<LoopbackTechOpFilter> = getCurrentFilter({
    paginationState,
    filtersState,
    seasonId,
    farmId,
  });

  const filterNormalized = { filter: normalizeLoopbackFilter(filter), onlyOriginal };
  const qsn = toQueryString(filterNormalized as Record<string, unknown>);
  return qsn;
}

function listCountQueryString(searchParams: TechOpsRequest): string {
  const { paginationState, filtersState, seasonId, farmId, onlyOriginal } = searchParams;
  const filter: ILoopbackFilter<LoopbackTechOpFilter> = getCurrentFilter({
    paginationState,
    filtersState,
    seasonId,
    farmId,
  });

  const filterExtended: Record<string, unknown> = {
    ...filter,
    ...{ onlyOriginal: onlyOriginal },
  };
  const qs = toQueryString(filterExtended);
  return qs;
}

function normalizeLoopbackFilter(filter: ILoopbackFilter): ILoopbackFilter {
  return {
    include: [
      { relation: "appUser", scope: { fields: ["fullName"] } },
      { relation: "crop", scope: { fields: ["name"] } },
      { relation: "farm", scope: { fields: ["name"] } },
      { relation: "farmLand", scope: { fields: ["name"] } },
      { relation: "techOperationGroup", scope: { fields: ["name"] } },
    ],
    ...filter,
  };
}

export const {
  useTechOpsListQuery,
  useTechOpsListCountQuery,
  useFetchTechOpQuery,
  useLazyFetchTechOpQuery,
  useAddTechOpMutation,
  useDeleteTechOpMutation,
  useUpdateTechOpMutation,
} = techOpsApiSlice;

export const useTechOpsListQueryState =
  techOpsApiSlice.endpoints.techOpsList.useQueryState;

export const useFetchTechOpQueryState =
  techOpsApiSlice.endpoints.fetchTechOp.useQueryState;
