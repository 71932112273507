import { stringify } from "qs";
import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import {
  TObservationsRequest,
  getObservationsListFilter,
} from "../containers/observations-list/observations-list-utils";
import { Observation } from "../shared/models/observation";
import { ILoopbackFilter } from "../../../modules/filter/shared/interfaces/loopback";
import { LoopbackObservationsFilter } from "../containers/observations-list/observations-list-utils";
import { IObservationDto } from "../shared/dtos/observations.dto";
import { devlog } from "../../../shared/utils/log";
import { toQueryString } from "../../../shared/utils/auth-token";

const observationsUrl = "ObservationsV2";

export const observationsApiSlice = createApi({
  reducerPath: "api-observations",
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl:
        process.env.REACT_APP_API_ENDPOINT || ".env: define REACT_APP_API_ENDPOINT",
    }),
    { maxRetries: 1 }
  ),
  tagTypes: ["ObservationsList", "ObservationsListCount"],
  endpoints: (builder) => ({
    observation: builder.query<Observation, string>({
      query: (observationId) => {
        const finalUrl = observationsUrl + "/" + observationId + "?" + toQueryString({});
        return { url: finalUrl, method: "GET" };
      },
      transformResponse: (dto: IObservationDto) => {
        const model = Observation.fromDto(dto);
        return model;
      },
    }),

    observationsList: builder.query<Observation[], TObservationsRequest>({
      query: (observationsRequest: TObservationsRequest) => {
        const qs = listQueryString(observationsRequest);

        const relPath = `${observationsUrl}?${qs}`;

        return { url: relPath, method: "GET" };
      },
      transformResponse: (dtos: IObservationDto[]) => {
        const models = dtos.map((dto) => {
          const model = Observation.fromDto(dto);
          return model;
        });
        return models;
      },
    }),

    observationsListCount: builder.query<number, TObservationsRequest>({
      query: (observationsRequest) => {
        devlog(`observationsListCount():`, observationsRequest);
        const qs = listCountQueryString(observationsRequest);
        const relPath = `${observationsUrl}/count?${qs}`;
        return { url: relPath, method: "GET" };
      },
      transformResponse: (dto: { count: number }) => {
        return dto.count;
      },
    }),
  }),
});

function listQueryString(observationsRequest: TObservationsRequest): string {
  const filter: ILoopbackFilter<LoopbackObservationsFilter> =
    getObservationsListFilter(observationsRequest);

  const { onlyOriginal } = observationsRequest;

  const filterNormalized = { filter: normalizeLoopbackFilter(filter) };

  const nonLoopbackFilterParams = stringify({ onlyOriginal });

  const qsn = toQueryString(filterNormalized as Record<string, unknown>);

  const finalQsn = qsn + "&" + nonLoopbackFilterParams;
  return finalQsn;
}

function listCountQueryString(observationsRequest: TObservationsRequest): string {
  const filter: ILoopbackFilter<LoopbackObservationsFilter> =
    getObservationsListFilter(observationsRequest);

  const filterAsRecord: Record<string, unknown> = {
    ...filter,
  };
  const qs = toQueryString(filterAsRecord);
  return qs;
}

function normalizeLoopbackFilter(filter: ILoopbackFilter): ILoopbackFilter {
  return {
    include: [
      { relation: "appUser", scope: { fields: ["fullName"] } },
      { relation: "crop", scope: { fields: ["name"] } },
      { relation: "farm", scope: { fields: ["name"] } },
      { relation: "farmLand", scope: { fields: ["name"] } },
      { relation: "phenoPhase", scope: { fields: ["name"] } },
      {
        relation: "observationPhotos",
        scope: {
          fields: ["id", "s3Filename", "s3FileExtension", "isDeleted"],
        },
      },
    ],
    ...filter,
  };
}

export const {
  useObservationQuery,
  useObservationsListQuery,
  useObservationsListCountQuery,
} = observationsApiSlice;
