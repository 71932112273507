import simplify from "simplify-geojson";
import { memoize } from "lodash";
import { CropMap } from "../services/geojson.service";

/**
 * TODO: after backend zoom implementation will be integrated need to check performance,
 * maybe rtkq cashing will help, or multiple requests to backend will be ok
 */

/**
 * Calculates tolerance based on zoom level
 * @param {number} zoomLevel - Current zoom level of the map
 * @returns {number} tolerance - Calculated tolerance for simplification
 */
function calculateTolerance(zoomLevel: number): number {
  const maxZoom = 18;
  const minZoom = 6;
  const maxTolerance = 0.001;
  const minTolerance = 0.00000001;

  // Ensure the zoom level is within the expected range
  const clampedZoom = Math.max(minZoom, Math.min(maxZoom, zoomLevel));

  // Calculate tolerance linearly between maxTolerance and minTolerance
  return (
    maxTolerance -
    ((clampedZoom - minZoom) / (maxZoom - minZoom)) * (maxTolerance - minTolerance)
  );
}

/**
 * Simplifies GeoJSON based on zoom level
 * @param {object} geoJsonData - The GeoJSON object to simplify
 * @param {number} zoomLevel - The current zoom level of the map
 * @returns {object} - Simplified GeoJSON object
 */
export function simplifyGeoJsonBasedOnZoom(
  geoJsonData: CropMap,
  zoomLevel: number
): CropMap {
  const tolerance = calculateTolerance(zoomLevel);
  return simplify(geoJsonData, tolerance);
}

// TODO: need to find better solution (based on rtkq cashing)
export const memoizedSimplifyGeoJson = memoize(
  (geoJsonData: CropMap, zoomLevel: number) =>
    simplifyGeoJsonBasedOnZoom(geoJsonData, zoomLevel),
  (geoJsonData: CropMap, zoomLevel) => {
    const firstFeatureId =
      !!geoJsonData.features.length && geoJsonData.features[0].properties.farmLandId;
    return [zoomLevel, geoJsonData.type, firstFeatureId].join("-");
  }
);
